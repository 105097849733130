exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-accessibility-mdx": () => import("./../../../src/pages/accessibility.mdx" /* webpackChunkName: "component---src-pages-accessibility-mdx" */),
  "component---src-pages-benefitsforparents-mdx": () => import("./../../../src/pages/benefitsforparents.mdx" /* webpackChunkName: "component---src-pages-benefitsforparents-mdx" */),
  "component---src-pages-benefitsforschools-mdx": () => import("./../../../src/pages/benefitsforschools.mdx" /* webpackChunkName: "component---src-pages-benefitsforschools-mdx" */),
  "component---src-pages-benefitsforstudents-mdx": () => import("./../../../src/pages/benefitsforstudents.mdx" /* webpackChunkName: "component---src-pages-benefitsforstudents-mdx" */),
  "component---src-pages-benefitsforteachers-mdx": () => import("./../../../src/pages/benefitsforteachers.mdx" /* webpackChunkName: "component---src-pages-benefitsforteachers-mdx" */),
  "component---src-pages-community-js": () => import("./../../../src/pages/community.js" /* webpackChunkName: "component---src-pages-community-js" */),
  "component---src-pages-contact-mdx": () => import("./../../../src/pages/contact.mdx" /* webpackChunkName: "component---src-pages-contact-mdx" */),
  "component---src-pages-contactus-js": () => import("./../../../src/pages/contactus.js" /* webpackChunkName: "component---src-pages-contactus-js" */),
  "component---src-pages-dynosblossoms-js": () => import("./../../../src/pages/dynosblossoms.js" /* webpackChunkName: "component---src-pages-dynosblossoms-js" */),
  "component---src-pages-dynosprimary-js": () => import("./../../../src/pages/dynosprimary.js" /* webpackChunkName: "component---src-pages-dynosprimary-js" */),
  "component---src-pages-forgotpassword-js": () => import("./../../../src/pages/forgotpassword.js" /* webpackChunkName: "component---src-pages-forgotpassword-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-privacy-mdx": () => import("./../../../src/pages/privacy.mdx" /* webpackChunkName: "component---src-pages-privacy-mdx" */),
  "component---src-pages-signin-js": () => import("./../../../src/pages/signin.js" /* webpackChunkName: "component---src-pages-signin-js" */),
  "component---src-pages-signup-js": () => import("./../../../src/pages/signup.js" /* webpackChunkName: "component---src-pages-signup-js" */),
  "component---src-pages-styleguide-mdx": () => import("./../../../src/pages/styleguide.mdx" /* webpackChunkName: "component---src-pages-styleguide-mdx" */),
  "component---src-pages-terms-mdx": () => import("./../../../src/pages/terms.mdx" /* webpackChunkName: "component---src-pages-terms-mdx" */)
}

