import decode from "jwt-decode";
import { navigate } from "gatsby"
import { getCookie } from "./cookie";
import { productViewerUrl } from "../config";
import { Auth } from "aws-amplify";
import { deleteCookieString } from "./cookie"

const getQueryParams = (params, url) => {
  let href = url;
  //this expression is to get the query strings
  let reg = new RegExp(`[?&]${params}=([^&#]*)`, "i");
  let queryString = reg.exec(href);
  return queryString ? queryString[1] : null;
};

const getUser = () => {
  const token = getCookie("dyn_session");
  return decode(token);
};

const range = (start, end) => {
  let length = end - start + 1;
  /*
  	Create an array of certain length and set the elements within it from
    start value to end value.
  */
  return Array.from({ length }, (_, idx) => idx + start);
};

const openViewer = (productId) => `${productViewerUrl}/?productid=${productId}`;

const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

const validatePassword = (password) => {
  const re =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\-\"!@#%&\/,><\':;|_~`])\S{8,99}$/;
  return re.test(String(password));
};

const passwordValidation = (password) => {
  const uppercaseRegExp   = /(?=.*?[A-Z])/;
  const lowercaseRegExp   = /(?=.*?[a-z])/;
  const digitsRegExp      = /(?=.*?[0-9])/;
  const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
  const minLengthRegExp   = /.{8,}/;
  if(!uppercaseRegExp.test(password)) {
      return "Must have at least one uppercase letter"
  } else if(!lowercaseRegExp.test(password)) {
      return "Must have at least one lowercase letter"
  } else if(!digitsRegExp.test(password)) {
      return "Must have at least one number"
  } else if(!specialCharRegExp.test(password)) {
    return "Must have at least one Special Characters"
  } else if(!minLengthRegExp.test(password)) {
      return "Must be at least minumum 8 characters"
  } else if(password === "") {
      return ""
  } else {
    return ""
  }
}

const validatePhone = (phone) => {
  const re = /^\d{10}$/;
  return re.test(Number(phone));
};

const logout = async() => {
  document.cookie = deleteCookieString(`dyn_session`)
  document.cookie = deleteCookieString(`accessToken`)
  document.cookie = deleteCookieString(`accessToken_s`)
  document.cookie = deleteCookieString(`accessToken_c`)
  document.cookie = deleteCookieString(`accessToken_m`)
  document.cookie = deleteCookieString(`accessToken_a`)
  localStorage.clear()
  sessionStorage.clear()
  try {
    await Auth.signOut()
    navigate("/")
  } catch (error) {
    navigate("/")
  }
}

export {
  getQueryParams,
  getUser,
  range,
  openViewer,
  validateEmail,
  validatePassword,
  validatePhone,
  logout,
  passwordValidation
};
