import ApolloClient from 'apollo-boost'
import fetch from 'isomorphic-fetch'
import { getCookie } from "../utils/cookie";

// Get the URI for Apollo backend from .env file or use localhost
const API_URI = process.env.GATSBY_GRAPHQL_ENDPOINT

const token = getCookie("dyn_session");
// Export the Apollo connection
export const client = new ApolloClient({
  uri: API_URI,
  fetch,
  headers: {
    authorization: token ? `Bearer ${token}` : "",
    "x-access-token": token,
  }
})