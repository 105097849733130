import { pipe } from "ramda";
import { backendPoint } from "../config";
import * as xhr from "../utils/xhr";
export function getDomain(w) {
  const url = w.location.origin;
  if (url.includes("localhost")) return "localhost";

  return pipe(
    (x) => x.split("."),
    (xs) => xs.slice(-2),
    (xs) => xs.join("."),
    (x) => `.${x}`
  )(url);
}
function makeCookieString(name, value, days, inseconds = false) {
  let expires = "";
  if (days && !inseconds) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = `; expires=${date.toGMTString()}`;
  }
  if (inseconds) {
    const date = new Date(days * 1000);
    expires = `; expires=${date.toGMTString()}`;
  }
  return `${name}=${value}${expires};domain=${getDomain(window)}; path=/`;
}
function setGoogleCookieString(name, value, expires_in) {
  const date = new Date();
  date.setTime(date.getTime() + expires_in * 1000);
  const expires = `; expires=${date.toGMTString()}`;
  document.cookie = `${name}=${value}${expires};domain=${getDomain(
    window
  )}; path=/`;
}
function getCookie(name) {
  if(typeof document !== `undefined`) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
      return decodeURIComponent(parts.pop().split(";").shift()) || "";
    }
    return "";
  }
  return ""
}
async function getNewGoogleAccessToken() {
  return xhr
    .post(backendPoint, {
      query: "query { userIdentity }",
    })
    .then((res) => res.json())
    .then((data) => {
      const {
        data: {
          userIdentity: { identities = [] },
        },
      } = data;
      const googleIdentity = identities.find(
        ({ provider }) => provider === "google-oauth2"
      );
      if (googleIdentity && googleIdentity.refresh_token) {
        return googleIdentity.refresh_token;
      } else {
        return null;
      }
    });
}

async function setGoogleAccessTokenToCookie(name, refresh_token) {
  const query = `query { newUserIdentityForGoogle }`;

  return xhr
    .getNewGoogleToken(backendPoint, { query }, refresh_token)
    .then((r) => r.json())
    .then((result) => {
      const hasErrors = !!result.errors;
      if (hasErrors) {
        console.log(result.errors); // eslint-disable-line no-console
        return null;
      } else {
        setGoogleCookieString(
          name,
          result.data.newUserIdentityForGoogle.access_token,
          result.data.newUserIdentityForGoogle.expires_in
        );
        return result.data.newUserIdentityForGoogle.access_token;
      }
    });
}

async function checkAndGetValidGToken(token_name) {
  // 1. Get google token info
  let accessToken = await getCookie(token_name);
  let tokenInfo = null;
  let getNewToken = false;
  if (accessToken && accessToken.length > 0) {
    tokenInfo = await getGoogleTokenInfo(accessToken);
    if (tokenInfo.error) getNewToken = true;
  } else {
    getNewToken = true;
  }
  // 2. CHeck if this returns an error..
  if (getNewToken) {
    //re-issue the token
    const newToken = await getNewGoogleAccessToken(accessToken);
    if (newToken) {
      accessToken = await setGoogleAccessTokenToCookie(token_name, newToken);
      return accessToken;
    } else {
      //Invalid Sign in
      return null;
    }
  } else {
    return accessToken;
    //TODO: Check if this has the required scopes?
  }
}
async function getGoogleTokenInfo(accessToken) {
  const url =
    "https://www.googleapis.com/oauth2/v1/tokeninfo?access_token=" +
    accessToken;
  return fetch(url, {
    method: "GET",
  }).then((r) => r.json());
}
function deleteCookieString(name) {
  return `${name}=; path=/; domain=${getDomain(
    window
  )}; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
}
function deleteCookie(name) {
  document.cookie = deleteCookieString(name);
}

export {
  makeCookieString,
  getCookie,
  deleteCookieString,
  deleteCookie,
  checkAndGetValidGToken,
};
