export const domain = process.env.GATSBY_AUTH_DOMAIN;
export const redirectSignIn = process.env.GATSBY_REDIRECT_SIGN_IN;
export const redirectSignOut = process.env.GATSBY_REDIRECT_SIGN_OUT;
export const regionName = process.env.GATSBY_AWS_REGION_NAME;
export const appDomain = process.env.GATSBY_APP_DOMAIN;
export const userpoolId = process.env.GATSBY_USER_POOL_ID
export const userpoolClientId = process.env.GATSBY_USER_POOL_CLIENT_ID
export const cognitoRegionName = process.env.GATSBY_COGNITO_REGION_NAME
export const publicAccessType = "PUBLIC"
export const privateAccessType = "PRIVATE"
export const courseType = "Course"
export const bundleType = "Bundle"